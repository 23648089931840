import React, { useState, useEffect } from 'react';

const useAudio = (url: string): [boolean, () => void] => {
    // Dodać że po przemieszczeniu na inną podstronę audio się zatrzymuje
    let audio: any = null;
    if (typeof window !== 'undefined') {
        [audio] = useState(new Audio(url));
    }
    const [playing, setPlaying] = useState(false);

    const toggle = () => setPlaying(!playing);

    useEffect(() => {
        playing ? audio.play() : audio.pause();
    }, [playing]);

    useEffect(() => {
        audio.addEventListener('ended', () => setPlaying(false));
        return () => {
            audio.removeEventListener('ended', () => setPlaying(false));
        };
    }, []);
    return [playing, toggle];
};

export { useAudio };
