import React, { useState, useCallback, useEffect, useRef, useMemo } from "react"
import { useSpring, animated } from "react-spring/"
import { DelayedLink } from "../"
import InteractWithUser from "./InteractWithUser"

const eventHandlerGenerator = function (
  handlerName,
  phase,
  props,
  setter,
  cbfn = useCallback
) {
  return cbfn(() => {
    setter(phase)
    props[handlerName] && props[handlerName]()
  }, [props[handlerName]])
}

function wait(delay = 500) {
  return new Promise((resolve) => {
    const timer = setTimeout(() => {
      clearTimeout(timer)
      resolve(true)
    }, delay)
  })
}

const PhaseButton = (props) => {
  const [phase, setPhase] = useState("idle") // onhover || idle || onclick

  useEffect(() => {
    props.onChangePhase && props.onChangePhase(phase)
    if (phase === "clicked") {
      wait().then(() => {
        setPhase("idle")
      })
    }
  }, [phase])

  const onMouseEnter = eventHandlerGenerator(
    "onMouseEnter",
    "hover",
    props,
    setPhase
  )
  const onMouseLeave = eventHandlerGenerator(
    "onMouseLeave",
    "idle",
    props,
    setPhase
  )
  const onMouseDown = eventHandlerGenerator(
    "onMouseDown",
    "clicking",
    props,
    setPhase
  )

  const onTouchStart = eventHandlerGenerator(
    "onTouchStart",
    "clicking",
    props,
    setPhase
  )
  const onTouchCancel = eventHandlerGenerator(
    "onTouchCancel",
    "idle",
    props,
    setPhase
  )
  const onTouchEnd = eventHandlerGenerator(
    "onTouchEnd",
    "idle",
    props,
    setPhase
  )
  const onTouchMove = eventHandlerGenerator(
    "onTouchMove",
    "clicking",
    props,
    setPhase
  )

  const onClick = eventHandlerGenerator("onClick", "clicked", props, setPhase)

  if (props.as === "a") {
    return (
      <animated.button
        {...props}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onMouseDown={onMouseDown}
        onTouchMove={onTouchMove}
        onTouchCancel={onTouchCancel}
        onTouchStart={onTouchStart}
        onTouchEnd={onTouchEnd}
      ></animated.button>
    )
  }

  return (
    <animated.button
      {...props}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onMouseDown={onMouseDown}
      onTouchMove={onTouchMove}
      onTouchCancel={onTouchCancel}
      onTouchStart={onTouchStart}
      onTouchEnd={onTouchEnd}
    ></animated.button>
  )
}
export { InteractWithUser }
export default PhaseButton
