import { motion } from 'framer-motion';
import React from 'react';

const variants = {
    initial: {
        scale: 1,
    },
    animation: {
        scale: 1,
        transition: {
            duration: 1,
        },
    },
    hover: {
        scale: 0.95,
        transition: {
            duration: 0.4,
        },
    },
    tap: {
        scale: 0.9,
        transition: {
            duration: 0.2,
        },
    },
};

interface IAnimatedButton {
    className?: string;
    submit?: string;
    onClick?: (e) => any;
    children: any;
}

const AnimatedButtonTwo = ({
    className,
    onClick,
    submit = 'false',
    children,
}: IAnimatedButton) => {
    return (
        <motion.button
            variants={variants}
            initial="initial"
            whileHover="hover"
            whileTap="tap"
            className={className}
            submit={submit}
            onClick={onClick}>
            {children}
        </motion.button>
    );
};

export default AnimatedButtonTwo;
