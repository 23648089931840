import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';

import useScrollHeight from '../../layout/Header/useScrollHeight';
import { useAudio } from '../../standard/Home/logic';
import {
    container,
    container__first,
    container__second,
    container__second__control,
    overlay,
    fab,
    fab__slideIn,
    fab__slideOut,
    fab__first,
    fab__second,
    fab__second__control,
    mobile,
    mobile__avatar,
    mobile__avatar__toggler,
    shortcut,
    shortcut__slideIn,
    shortcut__slideOut,
    shortcut__avatar,
    fab__second__control__play_pause
} from './fab.module.scss';
import Cancel from './images/cancel.svg';
import FabImage from './images/fabimage.svg';
import Pause from './images/pause.svg';
import Play from './images/play.svg';


interface IFab {}

const Fab: React.FC<IFab> = ({}) => {
    const [isTabClosed, setIsTabClosed] = useState(false);
    const [isOpen, setOpen] = useState(false);
    const [isScrolled, setScrolled] = useState(false);
    const { height } = useScrollHeight();

    const closeModal = () => {
        setOpen(false);
    };

    const toggleTabClosed = () => {
        setIsTabClosed((prev) => !prev);
    };

    const [playing, toggle] = useAudio(
        'https://sh.api.localhost-group.com/wp-content/uploads/2021/08/nagranie.mp3',
    );

    useEffect(() => {
        if (height > 500) {
            setScrolled(true);
        }
    });

    return (
        <>
            {isScrolled && (
                <>
                    <div
                        className={`${fab} ${
                            isTabClosed ? fab__slideOut : fab__slideIn
                        }`}>
                        <div className={fab__first}>
                            <img src={FabImage} alt="avatar" />
                        </div>
                        <div className={fab__second}>
                            <p>
                                Posłuchaj jakie wartości wyznajemy w firmie i
                                jacy jesteśmy dla naszych partnerów
                            </p>
                            <div className={fab__second__control}>
                                <button onClick={toggleTabClosed}>
                                    <img src={Cancel} alt="close" />
                                </button>
                                <button
                                    onClick={toggle}
                                    className={fab__second__control__play_pause}>
                                    {playing ? (
                                        <img src={Pause} alt="pause" />
                                    ) : (
                                        <img src={Play} alt="play" />
                                    )}
                                </button>
                            </div>
                        </div>
                    </div>
                    <button
                        className={`${shortcut} ${
                            isTabClosed ? shortcut__slideIn : shortcut__slideOut
                        }`}
                        onClick={toggleTabClosed}>
                        <div className={shortcut__avatar}>
                            <img src={FabImage} alt="avatar" />
                        </div>
                    </button>
            

            <div
                className={mobile}>
                <div className={mobile__avatar}>
                    <img src={FabImage} alt="avatar" />
                    <div className={mobile__avatar__toggler}>
                        <button onClick={() => setOpen(true)}>
                            {playing ? (
                                <img onClick={toggle} src={Pause} alt="pause" />
                            ) : (
                                <img onClick={toggle} src={Play} alt="play" />
                            )}
                        </button>
                    </div>
                </div>
                <Modal
                    isOpen={isOpen}
                    onRequestClose={closeModal}
                    className={container}
                    overlayClassName={overlay}>
                    <div className={container__first}>
                        <img src={FabImage} alt="avatar" />
                    </div>
                    <div className={container__second}>
                        <p>
                            Posłuchaj jakie wartości wyznajemy w firmie i jacy
                            jesteśmy dla naszych partnerów
                        </p>

                        <div className={container__second__control}>
                            <div>
                                <button onClick={toggle}>
                                    {playing ? (
                                        <img src={Pause} alt="pause" />
                                    ) : (
                                        <img src={Play} alt="play" />
                                    )}
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal>
                </div>
                </>
            )}
        </>
    );
};

export default Fab;
